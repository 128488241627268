import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";

import API from "../../../utils/API";
import CSVDownlaod from "../../../components/CsvDownload";
import DateRange from "../../../components/formfields/DateRange";
import Datetime from "../../../components/fields/Datetime";
import LeadID from "../../../components/fields/LeadID";
import LeadSourceSelect from "../../../components/formfields/LeadSourceSelect";
import LeadStatus from "../../../components/status/LeadStatus";
import LeadStatusSelect from "../../../components/formfields/LeadStatusSelect";
import ListStat from "../../../components/ListStat";
import Loader from "../../../components/Loader";
import MoneyPub from "../../../components/fields/MoneyPub";
import MoneySystem from "../../../components/fields/MoneySystem";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import Pagination from "../../../components/Pagination";
import Product from "../../../components/fields/Product";
import Publisher from "../../../components/fields/Publisher";
import PublisherSelect from "../../../components/formfields/PublisherSelect";
import RCID from "../../../components/fields/RCID";
import RCIDSelect from "../../../components/formfields/RCIDSelect";
import Source from "../../../components/fields/Source";
import SubID from "../../../components/fields/SubID";
import dateFormat from "dateformat";
import { parseFilterValues } from "../../../utils/helpers/filterHelper";
import { useHistory } from "react-router-dom";
import SortDirection from "../../../components/SortDirection";

const DetailsBath = () => {
  const history = useHistory();
  const defaultDate =
    dateFormat(new Date().setDate(new Date().getDate() - 6), "mmm d, yyyy") +
    " to " +
    dateFormat(new Date(), "mmm d, yyyy");

  const defaultFiltrationState = {
    page: 1,
    perPage: 100,
    date: defaultDate,
    product: 3,
  };

  const urlParams = new URLSearchParams(history.location.search);
  const parsedParams = Object.fromEntries(urlParams.entries());
  const filtersParsed = parseFilterValues(parsedParams);

  const [filtration, setFiltration] = useState({
    ...defaultFiltrationState,
    ...filtersParsed,
  });
  const [leads, setLeads] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const changeData = (name, value) => {
    setFiltration({ ...filtration, [name]: value });

    history.push({
      pathname: history.location.pathname,
      search: new URLSearchParams({ ...filtration, [name]: value }).toString(),
    });
  };

  useEffect(() => {
    setLoading(true);

    API.post("/admin/lead/detailreportfulldata", {
      ...filtration
    }).then((response) => {
      setLeads(response.data.data.data);
      setData(response.data.data);
      setLoading(false);
    });
  }, [filtration]);

  return (
    <Container fluid className="p-0">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h3 className="h3 mb-3 float-left">Leads full data, Bath</h3>
        </div>
        <div className="col-auto">
          <CSVDownlaod
            url="/admin/lead/detailreportfulldatacsv"
            filename="BathLeadsFullData"
            filtration={filtration}
          />
        </div>
      </div>
      <Row form>
        <Col md={6}>
          <DateRange
            onChange={changeData}
            name={"date"}
            value={filtration?.date}
          />
        </Col>
        <Col md={6}>
          <PublisherSelect
            name={"publisher"}
            onChange={changeData}
            value={filtration?.publisher}
          />
        </Col>
      </Row>
      <Row form className="mt-1">
        <Col md={4}>
          <LeadStatusSelect
            name={"status"}
            onChange={changeData}
            value={filtration?.status}
          />
        </Col>
        <Col md={4}>
          <LeadSourceSelect
            name={"source"}
            onChange={changeData}
            value={filtration?.source}
          />
        </Col>
        <Col md={4}>
          <RCIDSelect
            name={"rcid"}
            onChange={changeData}
            product={filtration?.product}
            publisher={filtration?.publisher}
            value={filtration?.rcid}
          />
        </Col>
      </Row>
      {loading && <Loader />}
      <Card className="mt-2">
        {!loading && leads?.length > 0 ? (
          <div className="table-responsive">
            <ListStat data={data} />
            <Table>
              <thead>
                <tr>
                  <th>Id<SortDirection fieldName={'id'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Date<SortDirection fieldName={'date'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Status<SortDirection fieldName={'status'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Reason</th>
                  <th>Product<SortDirection fieldName={'product'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Publisher<SortDirection fieldName={'publisher'} filters={filtration} changeFilter={changeData}/></th>
                  <th>RCID<SortDirection fieldName={'rcid'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Source<SortDirection fieldName={'source'} filters={filtration} changeFilter={changeData}/></th>
                  <th>SubId<SortDirection fieldName={'subid'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Name<SortDirection fieldName={'name'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Email<SortDirection fieldName={'email'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Phone<SortDirection fieldName={'phone'} filters={filtration} changeFilter={changeData}/></th>
                  <th>State<SortDirection fieldName={'state'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Zip<SortDirection fieldName={'zip'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Address<SortDirection fieldName={'address'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Homeowner<SortDirection fieldName={'homeowner'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Project<SortDirection fieldName={'projecttype'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Add/Remove walls<SortDirection fieldName={'addremovewalls'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Total<SortDirection fieldName={'total'} filters={filtration} changeFilter={changeData}/></th>
                  <th>Pub<SortDirection fieldName={'pub'} filters={filtration} changeFilter={changeData}/></th>
                  <th>System<SortDirection fieldName={'system'} filters={filtration} changeFilter={changeData}/></th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <LeadID id={lead.lead.id} />
                      </td>
                      <td>
                        <Datetime dateTime={lead.lead.dateadd} />
                      </td>
                      <td>
                        <LeadStatus status={lead.lead.status} />
                      </td>
                      <td>
                        {lead.lead.exceptions.map((exception, excKey) => {
                          return (
                            <div key={excKey}>
                              <nobr>{exception}</nobr>
                            </div>
                          );
                        })}
                      </td>
                      <td>
                        <Product product={lead.lead.product} />
                      </td>
                      <td>
                        <Publisher publisher={lead.lead.publisher} />
                      </td>
                      <td>
                        <RCID id={lead.lead.rcid} />
                      </td>
                      <td>
                        <Source source={lead.lead.leadsource} />
                      </td>
                      <td>
                        <SubID subid={lead.lead.subid} />
                      </td>
                      <td>
                        {lead.leaddata.firstname} {lead.leaddata.lastname}
                      </td>
                      <td>{lead.leaddata.email}</td>
                      <td>{lead.leaddata.phone}</td>
                      <td>{lead.leaddata.state}</td>
                      <td>{lead.leaddata.zip}</td>
                      <td>{lead.leaddata.address}</td>
                      <td>{lead.leaddata.homeowner}</td>
                      <td>{lead.leaddata.projecttype}</td>
                      <td>{lead.leaddata.addremovewalls}</td>
                      <td>
                        <MoneyTotal
                          money={lead.lead.earntotal}
                          moneyOriginal={lead.lead.earntotaloriginal}
                        />
                      </td>
                      <td>
                        <MoneyPub
                          money={lead.lead.earnpub}
                          moneyOriginal={lead.lead.earnpuboriginal}
                        />
                      </td>
                      <td>
                        <MoneySystem
                          money={lead.lead.earntotal - lead.lead.earnpub}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          !loading && (
            <CardBody className="text-center">
              <h5>Data not found</h5>
            </CardBody>
          )
        )}
      </Card>
      {data?.page > 0 && data?.pages > 0 && (
        <Pagination
          onChange={changeData}
          name={"page"}
          page={data.page}
          totalPages={data.pages}
          loading={loading}
        />
      )}
    </Container>
  );
};

export default DetailsBath;
