import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import { Button, FormGroup, Label } from "reactstrap";
import React, { useState } from "react";

import API from "../../../utils/API";
import LeadLinkInformation from "./LeadLinkInformation";
import { toastr } from "react-redux-toastr";

const LeadFieldsPage = ({ fields, leadId, editConfig }) => {
  const [editMode, setEditMode] = useState(false);
  const [leadBody, setLeadBody] = useState(fields);
  const [leadInitialBody, setLeadInitialBody] = useState(fields);

  const [errors, setErrors] = useState(null);

  const handleChange = (e) => {
    setLeadBody({ ...leadBody, [e.target.name]: e.target.value });
  };

  const submit = () => {
    API.post("/admin/leadedit/" + leadId, leadBody)
      .then((response) => {
        setErrors(null);
        toastr.success("Success", "Lead data was successfully saved");
        setEditMode(false);
        setLeadInitialBody(response.data.data);
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.data);
          toastr.error("Error", "Error occured while saving lead data");
        } else {
          toastr.error("Error", "Error occured while saving lead data");
        }
      });
  };

  return (
    <div>
      {editMode ? (
        <AvForm onValidSubmit={submit}>
          {editConfig.map((field, index) => {
            if (field.type === "string") {
              return (
                <FormGroup key={index}>
                  <Label>{field.name}</Label>
                  <AvInput
                    className={errors && errors[field.name] && "border-danger"}
                    onChange={handleChange}
                    required
                    type="text"
                    name={field.name}
                    value={leadBody[field.name]}
                    placeholder={"Enter " + field.name}
                  />
                  {errors && errors[field.name] && (
                    <div className="text-danger font-size-sm">
                      {errors[field.name]}
                    </div>
                  )}
                </FormGroup>
              );
            } else if (field.type === "options") {
              return (
                <FormGroup key={index}>
                  <Label>{field.name}</Label>
                  <AvField
                    className={errors && errors[field.name] && "border-danger"}
                    type="select"
                    name={field.name}
                    value={leadBody[field.name]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select...</option>
                    {field.options.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </AvField>
                  {errors && errors[field.name] && (
                    <div className="text-danger font-size-sm">
                      {errors[field.name]}
                    </div>
                  )}
                </FormGroup>
              );
            }
          })}
          <div className="text-center">
            <Button color="primary">Save</Button>
            <Button
              color="secondary-outline"
              type="button"
              onClick={() => setEditMode(false)}
            >
              Cancel
            </Button>
          </div>
        </AvForm>
      ) : (
        <>
          <ul className="list-unstyled mb-0">
            {Object.keys(leadInitialBody).map((field, index) => {
              return (
                <li key={index} className="mt-1">
                  {field}: <strong>{leadInitialBody[field]}</strong>{" "}
                  {(field === "address" || field === "zip") && (
                    <LeadLinkInformation
                      leadBody={leadInitialBody}
                      linkPage={field}
                    />
                  )}
                </li>
              );
            })}
          </ul>
          {editConfig && (
            <button
              className="btn btn-outline-primary btn-sm mt-2"
              onClick={() => setEditMode(true)}
            >
              Edit
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default LeadFieldsPage;
