import async from "../components/Async";

import {
  Sliders as SlidersIcon, Users,
  Users as UsersIcon, Folder, Settings, Tool, BarChart, Layers
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import Admins from "../pages/users/Admins";
import Admin from "../pages/users/Admin";
import Details from "../pages/leads/Details";
import Summary from "../pages/leads/Summary";
import Publishers from "../pages/publisher/Publishers";
import SummaryReport from "../pages/publisher/SummaryReport";
import Lenders from "../pages/lenders/Lenders";
import Orders from "../pages/lenders/Orders";
import OrderSummary from "../pages/lenders/OrderSummary";
import LendersDetailsReport from "../pages/lenders/LendersDetailsReport";
import Source from "../pages/leads/Source";
import Lead from "../pages/leads/Lead";
import LenderForm from "../pages/lenders/Form";
import Lender from "../pages/lenders/Lender";
import OrderForm from "../pages/lenders/OrderForm";
import Order from "../pages/lenders/Order";
import SendFormatEdit from "../pages/lenders/order/SendFormatEdit";
import ConfigurationEdit from "../pages/lenders/order/ConfigurationEdit";
import Publisher from "../pages/publisher/Publisher";
import PublisherForm from "../pages/publisher/PublisherForm";
import OrderEditForm from "../pages/lenders/order/OrderEditForm";
import RcidForm from "../pages/publisher/rcid/RcidForm";
import Rcid from "../pages/publisher/rcid/Rcid";
import Pings from "../pages/leads/Pings";
import Ping from "../pages/leads/Ping";
import PingPostReport from "../pages/leads/PingPostReport";
import PagesContent from "../pages/settings/landingpagecontent/PagesContent";
import PageContent from "../pages/settings/landingpagecontent/PageContent";
import Pixels from "../pages/publisher/Pixels";
import PixelForm from "../pages/publisher/PixelForm";
import Pixel from "../pages/publisher/Pixel";
import CostUpload from "../pages/tools/costupload/CostUpload";
import CostUploadLog from "../pages/tools/costupload/CostUploadLog";
import CostUploadLogItem from "../pages/tools/costupload/CostUploadLogItem";
import Search from "../pages/leads/Search";
import PageContentHistory from "../pages/settings/landingpagecontent/PageContentHistory";
import Returnreasons from "../pages/settings/returnreason/Returnreasons";
import Returnreason from "../pages/settings/returnreason/Returnreason";
import Returns from "../pages/tools/returns/Returns";
import Requests from "../pages/tools/returns/Requests";
import DetailsDebtSettlement from "../pages/leads/fulldata/DetailsDebtSettlement";
import DetailsWindow from "../pages/leads/fulldata/DetailsWindow";
import DetailsRoofing from "../pages/leads/fulldata/DetailsRoofing";
import DetailsSiding from "../pages/leads/fulldata/DetailsSiding";
import DetailsBath from "../pages/leads/fulldata/DetailsBath";
import ThankYouPageClicks from "../pages/lenders/ThankYouPageClicks";
import RevenueUpload from "../pages/tools/revenueupload/RevenueUpload";
import RevenueUploadLog from "../pages/tools/revenueupload/RevenueUploadLog";
import RevenueUploadLogItem from "../pages/tools/revenueupload/RevenueUploadLogItem";
import LenderSummary from "../pages/lenders/LenderSummary";
import BillingReport from "../pages/lenders/billingreport/BillingReport";
import SourceReport from "../pages/leads/SourceReport";
import CapManagement from "../pages/lenders/capmanagement/CapManagement";
import AreaCodeScrubbers from "../pages/settings/areacodescrubber/AreaCodeScrubbers";
import AreaCodeScrubber from "../pages/settings/areacodescrubber/AreaCodeScrubber";
import Emailscrubbers from "../pages/settings/emailscrubber/EmailScrubbers";
import Emailscrubber from "../pages/settings/emailscrubber/EmailScrubber";
import PhoneNumberScrubbers from "../pages/settings/phonenumberscrubber/PhoneNumberScrubbers";
import PhoneNumberScrubber from "../pages/settings/phonenumberscrubber/PhoneNumberScrubber";
import BadFakeWordScrubbers from "../pages/settings/badfakewordscrubber/BadFakeWordScrubbers";
import BadFakeWordScrubber from "../pages/settings/badfakewordscrubber/BadFakeWordScrubber";
import LenderPingPostReport from "../pages/lenders/pingpostreport/LenderPingPostReport";
import DetailsSolar from "../pages/leads/fulldata/DetailsSolar";
import ExclusivePricePointReport from "../pages/reports/ExclusivePricePointReport"
import Profile from "../pages/account/Profile";
import ChangePassword from "../pages/account/ChangePassword";
import MultiFactorAuth from "../pages/account/MultiFactorAuth";
import PublisherUsers from "../pages/users/PublisherUsers";
import PublisherUserForm from "../pages/users/PublisherUserForm";
import ElectricityProvider from "../pages/settings/addelectricityprovider/ElectricityProvider";
import ElectricityProviders from "../pages/settings/addelectricityprovider/ElectricityProviders";
import ElectricityProviderMapping from "../pages/settings/addelectricityprovider/ElectricityProviderMapping";
import SubIDReport from "../pages/publisher/SubIDReport";
import LenderUsers from "../pages/users/LenderUsers";
import LenderUserForm from "../pages/users/LenderUserForm";
import Log from "../pages/Log";
import DetailsHomeSeller from "../pages/leads/fulldata/DetailsHomeSeller";

const Ecommerce = async(() => import("../pages/dashboards/Admin"));

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  icon: SlidersIcon,
  component: Ecommerce,
  header: "RGR Marketing",
  privateRouter: true,
  children: null
};

const systemLogRoutes = {
  path: "/systemlog",
  name: "System log",
  icon: Layers,
  component: Log,
  privateRouter: true,
  children: null
};

const usermanagementRoutes = {
  name: "User management",
  path: "/user",
  icon: Users,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/user/admins",
      name: "Admins",
      privateRouter: true,
      component: Admins
    },
    {
      path: "/user/pubusers",
      name: "Publisher users",
      privateRouter: true,
      component: PublisherUsers
    },
    {
      path: "/user/pubusers/item/:id?",
      name: "Publisher user form",
      privateRouter: true,
      component: PublisherUserForm,
      hideInMenu: true
    },
    {
      path: "/user/admins/item/:id?",
      name: "New admin",
      privateRouter: true,
      component: Admin,
      hideInMenu: true
    },
    {
      path: "/user/lenders",
      name: "Lender users",
      privateRouter: true,
      component: LenderUsers
    },
    {
      path: "/user/lenders/item/:id?",
      name: "Lender user form",
      privateRouter: true,
      component: LenderUserForm,
      hideInMenu: true
    },
  ]
};

const settingsRoutes = {
  name: "Settings",
  path: "/settings",
  icon: Settings,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/settings/landingpagecontent",
      name: "Landing page content",
      privateRouter: true,
      component: PagesContent
    },
    {
      path: "/settings/landingpagecontent/new",
      name: "Landing page content",
      privateRouter: true,
      component: PageContent,
      hideInMenu: true
    },
    {
      path: "/settings/landingpagecontent/item/:id/:reverse?",
      name: "Landing page content",
      privateRouter: true,
      component: PageContent,
      hideInMenu: true
    },
    {
      path: "/settings/landingpagecontent/itemhistory/:id",
      name: "Landing page content history",
      privateRouter: true,
      component: PageContentHistory,
      hideInMenu: true
    },
    {
      path: "/settings/returnreason",
      name: "Return reasons",
      privateRouter: true,
      component: Returnreasons,
    },
    {
      path: "/settings/returnreason/item/:id?",
      name: "Return reason",
      privateRouter: true,
      component: Returnreason,
      hideInMenu: true
    },
    // {
    //   path: "/settings/areacodescrubber",
    //   name: "Area code scrubbers",
    //   privateRouter: true,
    //   component: AreaCodeScrubbers,
    // },
    {
      path: "/settings/areacodescrubber/item/:id?",
      name: "Area code scrubber",
      privateRouter: true,
      component: AreaCodeScrubber,
      hideInMenu: true
    },
    {
      path: "/settings/emailscrubber",
      name: "Email scrubber",
      privateRouter: true,
      component: Emailscrubbers,
    },
    {
      path: "/settings/emailscrubber/item/:id?",
      name: "Email scrubber",
      privateRouter: true,
      component: Emailscrubber,
      hideInMenu: true
    },
    {
      path: "/settings/phonenumberscrubber",
      name: "Phone number scrubber",
      privateRouter: true,
      component: PhoneNumberScrubbers,
    },
    {
      path: "/settings/phonenumberscrubber/item/:id?",
      name: "Phone number scrubber",
      privateRouter: true,
      component: PhoneNumberScrubber,
      hideInMenu: true
    },
    {
      path: "/settings/badfakewordscrubber",
      name: "Bad/Fake word scrubber",
      privateRouter: true,
      component: BadFakeWordScrubbers,
    },
    {
      path: "/settings/badfakewordscrubber/item/:id?",
      name: "Bad/Fake word scrubber",
      privateRouter: true,
      component: BadFakeWordScrubber,
      hideInMenu: true
    },
    {
      path: "/settings/electricityprovider",
      name: "Electricity provider",
      privateRouter: true,
      component: ElectricityProvider,
      hideInMenu: true
    },
    {
      path: "/settings/electricityprovidermapping",
      name: "Electricity Provider Mapping",
      privateRouter: true,
      component: ElectricityProviderMapping,
      hideInMenu: true
    },
    {
      path: "/settings/electricityproviders",
      name: "Electricity Provider",
      privateRouter: true,
      component: ElectricityProviders,
    },
  ]
};

const leadRoutes = {
  name: "Leads",
  path: "/lead",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/lead/report/details",
      name: "Details report",
      privateRouter: true,
      component: Details
    },
    {
      path: "/lead/report/summary",
      name: "Summary report",
      privateRouter: true,
      component: Summary
    },
    {
      path: "/lead/search",
      name: "Search",
      privateRouter: true,
      component: Search
    },
    {
      path: "/lead/ping/report/details",
      name: "Pings",
      privateRouter: true,
      component: Pings
    },
    {
      path: "/lead/source",
      name: "Source management",
      privateRouter: true,
      component: Source
    },
    {
      path: "/lead/report/pingpost",
      name: "Ping/Post report",
      privateRouter: true,
      component: PingPostReport
    },
    {
      path: "/lead/item/:id",
      name: "Lead",
      privateRouter: true,
      component: Lead,
      hideInMenu: true
    },
    {
      path: "/lead/ping/item/:id",
      name: "Ping",
      privateRouter: true,
      component: Ping,
      hideInMenu: true
    },
    {
      path: "/lead/sourcereport",
      name: "Source URL report",
      privateRouter: true,
      component: SourceReport
    },
  ]
};

const leadDataRoutes = {
  name: "Full lead data",
  path: "/detailsfullinfo",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/detailsfullinfo/debtsettlement",
      name: "Debt Settlement",
      privateRouter: true,
      component: DetailsDebtSettlement
    },
    {
      path: "/detailsfullinfo/windows",
      name: "Windows",
      privateRouter: true,
      component: DetailsWindow
    },
    {
      path: "/detailsfullinfo/roofing",
      name: "Roofing",
      privateRouter: true,
      component: DetailsRoofing
    },
    {
      path: "/detailsfullinfo/siding",
      name: "Siding",
      privateRouter: true,
      component: DetailsSiding
    },
    {
      path: "/detailsfullinfo/bath",
      name: "Bath",
      privateRouter: true,
      component: DetailsBath
    },
    {
      path: "/detailsfullinfo/solar",
      name: "Solar",
      privateRouter: true,
      component: DetailsSolar
    },
    {
      path: "/detailsfullinfo/homeseller",
      name: "Home Seller",
      privateRouter: true,
      component: DetailsHomeSeller
    },
  ]
};

const publisherRoutes = {
  name: "Publisher",
  path: "/publisher",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/publisher/list",
      name: "Publishers",
      privateRouter: true,
      component: Publishers
    },
    {
      path: "/publisher/report/summary",
      name: "Summary report",
      privateRouter: true,
      component: SummaryReport
    },
    {
      path: "/publisher/form/:id?",
      name: "Publisher form",
      privateRouter: true,
      hideInMenu: true,
      component: PublisherForm
    },
    {
      path: "/publisher/item/:id?",
      name: "Publisher",
      privateRouter: true,
      hideInMenu: true,
      component: Publisher
    },
    {
      path: "/publisher/rcid/form/:publisherid/:id?",
      name: "RCID",
      privateRouter: true,
      hideInMenu: true,
      component: RcidForm
    },
    {
      path: "/publisher/rcid/item/:id",
      name: "RCID",
      privateRouter: true,
      hideInMenu: true,
      component: Rcid
    },
    {
      path: "/publisher/pixel",
      name: "Pixels",
      privateRouter: true,
      component: Pixels
    },
    {
      path: "/publisher/pixel/form/:id?",
      name: "Pixel form",
      privateRouter: true,
      hideInMenu: true,
      component: PixelForm
    },
    {
      path: "/publisher/pixel/item/:id",
      name: "Pixel",
      privateRouter: true,
      hideInMenu: true,
      component: Pixel
    },
    {
      path: "/publisher/report/subidreport",
      name: "SubID report",
      privateRouter: true,
      component: SubIDReport
    },
  ]
};

const lenderRoutes = {
  name: "Lender",
  path: "/lender",
  icon: Folder,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/lender/list",
      name: "Lenders",
      privateRouter: true,
      component: Lenders
    },
    {
      path: "/lender/orders",
      name: "Orders",
      privateRouter: true,
      component: Orders
    },
    {
      path: "/lender/report/billing",
      name: "Billing report",
      privateRouter: true,
      component: BillingReport
    },
    {
      path: "/lender/report/order/summary",
      name: "Orders summary report",
      privateRouter: true,
      component: OrderSummary
    },
    {
      path: "/lender/report/summary",
      name: "Lenders summary report",
      privateRouter: true,
      component: LenderSummary
    },
    {
      path: "/lender/report/details",
      name: "Detail report",
      privateRouter: true,
      component: LendersDetailsReport
    },
    {
      path: "/lender/form/:id?",
      name: "Lender form",
      privateRouter: true,
      hideInMenu: true,
      component: LenderForm
    },
    {
      path: "/lender/item/:id?",
      name: "Lender",
      privateRouter: true,
      hideInMenu: true,
      component: Lender
    },
    {
      path: "/lender/order/form/:id",
      name: "New order",
      privateRouter: true,
      hideInMenu: true,
      component: OrderForm
    },
    {
      path: "/lender/order/item/:id",
      name: "Order",
      privateRouter: true,
      hideInMenu: true,
      component: Order
    },
    {
      path: "/lender/order/sendformat/:id",
      name: "Send format edit",
      privateRouter: true,
      hideInMenu: true,
      component: SendFormatEdit
    },
    {
      path: "/lender/order/configuration/:id",
      name: "Configuration edit",
      privateRouter: true,
      hideInMenu: true,
      component: ConfigurationEdit
    },
    {
      path: "/lender/order/edit/:id",
      name: "Order edit",
      privateRouter: true,
      hideInMenu: true,
      component: OrderEditForm
    },
    {
      path: "/lender/returnrequests",
      name: "Return requests",
      privateRouter: true,
      component: Requests
    },
    {
      path: "/lender/capmanagement",
      name: "Cap management",
      privateRouter: true,
      component: CapManagement
    },
    {
      path: "/lender/pingpostreport",
      name: "Ping/Post report",
      privateRouter: true,
      component: LenderPingPostReport
    }
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  privateRouter: false,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      privateRouter: false,
      component: SignIn
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      privateRouter: false,
      component: ResetPassword
    }
  ]
};

const toolsRoutes = {
  name: "Tools",
  path: "/tools",
  icon: Tool,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/tools/costupload",
      name: "Cost upload",
      privateRouter: true,
      component: CostUpload
    },
    {
      path: "/tools/costupload/log",
      name: "Cost upload log",
      privateRouter: true,
      component: CostUploadLog,
      hideInMenu: true
    },
    {
      path: "/tools/costupload/logitem/:id",
      name: "Cost upload log item",
      privateRouter: true,
      component: CostUploadLogItem,
      hideInMenu: true
    },
    {
      path: "/tools/returns",
      name: "Returns",
      privateRouter: true,
      component: Returns
    },
    {
      path: "/tools/revenueupload",
      name: "Revenue upload",
      privateRouter: true,
      component: RevenueUpload
    },
    {
      path: "/tools/revenueupload/log",
      name: "Revenue upload log",
      privateRouter: true,
      component: RevenueUploadLog,
      hideInMenu: true
    },
    {
      path: "/tools/revenueupload/logitem/:id",
      name: "Revenue upload log item",
      privateRouter: true,
      component: RevenueUploadLogItem,
      hideInMenu: true
    },
    {
      path: "/profile",
      name: "Profile settings",
      privateRouter: true,
      component: Profile,
      hideInMenu: true
    },
    {
      path: "/profile/password",
      name: "Change password",
      privateRouter: true,
      component: ChangePassword,
      hideInMenu: true
    },
    {
      path: "/profile/mfa",
      name: "Multi-factor authentication",
      privateRouter: true,
      component: MultiFactorAuth,
      hideInMenu: true
    }
  ]
};

const reportsRoutes = {
  name: "Reports",
  path: "/systemreport",
  icon: BarChart,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/systemreport/redirect/thankyoupage",
      name: "Thank You Page Clicks",
      privateRouter: true,
      component: ThankYouPageClicks
    },
    {
      path: "/systemreport/exclusivepricepointreport",
      name: "Exclusive Price Point Report",
      privateRouter: true,
      component: ExclusivePricePointReport,
    },
  ]
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  leadRoutes,
  leadDataRoutes,
  lenderRoutes,
  publisherRoutes,
  usermanagementRoutes,
  settingsRoutes,
  toolsRoutes,
  reportsRoutes,
  systemLogRoutes,
];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
  dashboardRoutes,
  leadRoutes,
  leadDataRoutes,
  lenderRoutes,
  publisherRoutes,
  usermanagementRoutes,
  settingsRoutes,
  toolsRoutes,
  reportsRoutes,
  systemLogRoutes,
];
