import React from "react";
import DateTime from "./Types/DateTime";
import IncludeExclude from "./Types/IncludeExclude";
import Duplicate from "./Types/Duplicate";
import InputInteger from "./Types/InputInteger";
import TokenRequirements from "./Types/TokenRequirements";
import State from "./Types/State";
import ElectricityProvider from "./Types/ElectricityProvider";
import Publisher from "./Types/Publisher";

const Filter = ({title,type,elementId,onClose}) => {
    switch (title) {
        case 'Date/Time':
            return (<DateTime title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Date/Time Lender':
            return (<DateTime title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'State':
            return (<State title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Zip code':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Home owner':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'By Publisher':
            return (<Publisher title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'RCID':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Duplicate':
            return (<Duplicate title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Overall Order Cap':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Cap Per Day':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Window service type':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Windows count':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Bath, add or remove walls':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Bath project type':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Roofing material':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Roofing, project type':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Siding project type':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Type of siding':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Token requirements':
            return (<TokenRequirements title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Cap Per Month':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Landing page':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Min debt amount':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Max debt amount':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Behind on payments':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Duplicate Lender':
            return (<Duplicate title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Overall Cap Lender':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Cap Per Day Lender':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Cap Per Month Lender':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Electricity provider':
            return (<ElectricityProvider title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Roof shade':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Min power bill':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Max power bill':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Overall Publisher Cap':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Daily Publisher Cap':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Monthly Publisher Cap':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Weekly Publisher Cap':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Overall RCID Cap':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Daily RCID Cap':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Monthly RCID Cap':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Weekly RCID Cap':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Credit status':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Property type':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Electricity provider order':
            return (<ElectricityProvider title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Property occupancy':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Property condition':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Property Type (Home Seller)':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Listed with Realtor':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Reason for Selling':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Time frame':
            return (<IncludeExclude title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Max home value':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Max owed amount':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Min home value':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        case 'Min owed amount':
            return (<InputInteger title={title} type={type} elementId={elementId} onClose={onClose}/>)
        default:
            return (<span>Filter nor found</span>)
    }
}

export default Filter;
