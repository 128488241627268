import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table} from "reactstrap";
import API from "../../../utils/API";
import Datetime from "../../../components/fields/Datetime";
import PostingStatus from "../../../components/status/PostingStatus";
import Order from "../../../components/fields/Order";
import LenderTitle from "../../../components/fields/LenderTitle";
import MoneyPub from "../../../components/fields/MoneyPub";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import OrderPostingLog from "../../../components/fields/OrderPostingLog";

const Delete = ({id,postLog,runUpdate}) => {

    const [modalshow,setModalshow] = useState(false)
    const [deleteComment,setDeleteComment] = useState(null)
    const [saving,setSaving] = useState(false)
    const [lendermodalshow,setLendermodalshow] = useState(false)
    const [orderId,setOrderId] = useState(null)

    const deleteLead = () => {
        setSaving(true);
        API.post(`/admin/lead/delete/${id}`,{comment:deleteComment}).then((response) => {
            runUpdate();
            setSaving(false);
        });
    }

    const deleteLeadLender = () => {
        setSaving(true);
        API.post(`/admin/leadlender/delete/${id}/${orderId}`,{comment:deleteComment}).then((response) => {
            runUpdate();
            setSaving(false);
            setLendermodalshow(false);
            setOrderId(null)
        });
    }

    return (
        <>
            <div className="m-2">
                <Button color="danger" onClick={() => setModalshow(true)}>Delete Lead from System</Button>
                <div><small>*This operation is irreversible.</small></div>
                {postLog?.length > 0 &&
                    <div className="table-responsive mt-3">
                        <Table>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Order</th>
                                <th>Lender</th>
                                <th>Publisher earning</th>
                                <th>Total earning</th>
                                <th>Comment</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                postLog.map((log, index) => {
                                    return (
                                        <tr key={index}>
                                            <th>
                                                <Button color="danger" onClick={() => {setLendermodalshow(true);setOrderId(log.lenderorder.id)}}>Remove from a buyer</Button>
                                            </th>
                                            <td><Datetime dateTime={log.date}/></td>
                                            <td><PostingStatus status={log.status}/></td>
                                            <td><Order order={log.lenderorder}/></td>
                                            <td><LenderTitle title={log.lenderorder.lender.title} id={log.lenderorder.lender.id}/></td>
                                            <td><MoneyPub money={log.earnpub} moneyOriginal={log.earnpuboriginal}/></td>
                                            <td><MoneyTotal money={log.earntotal} moneyOriginal={log.earntotaloriginal}/></td>
                                            <td>{log.comment}</td>
                                            <td>
                                                <OrderPostingLog id={log.id} isPing={false}/>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
            <React.Fragment>
                <Modal
                    isOpen={modalshow}
                    toggle={() => setModalshow(!modalshow)}
                    size="lg"
                    style={{maxWidth: '600px', width: '600px'}}
                >
                    <ModalHeader toggle={() => setModalshow(!modalshow)}>
                        Delete lead
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                        <label>Memo (not required)</label>
                        <textarea rows={5} className="form-control" name="memo" onChange={(e)=>setDeleteComment(e.target.value)} value={deleteComment}/>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        {saving ?
                            <Spinner color="primary" />
                            :
                            <Button color="danger" onClick={() => deleteLead()}>
                                Delete
                            </Button>
                        }
                    </ModalFooter>
                </Modal>
            </React.Fragment>

            <React.Fragment>
                <Modal
                    isOpen={lendermodalshow}
                    toggle={() => setLendermodalshow(!lendermodalshow)}
                    size="lg"
                    style={{maxWidth: '600px', width: '600px'}}
                >
                    <ModalHeader toggle={() => setLendermodalshow(!lendermodalshow)}>
                        Delete lender from lead
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:"#F7F9FC"}}>
                        Are you sure want to delete this lender from this lead?
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        {saving ?
                            <Spinner color="primary" />
                            :
                            <>
                                <Button color="danger" onClick={() => deleteLeadLender()}>
                                    Delete
                                </Button>
                                <Button color="secondary" onClick={() => setLendermodalshow(!lendermodalshow)}>
                                    Cancel
                                </Button>
                            </>

                        }
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        </>
    )
}

export default Delete
